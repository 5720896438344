import React, { Component } from "react";
import { connect } from "react-redux";
import {
  activeTransactions,
  closeProject,
  closedBriefProject,
  deleteProjectList,
  activeTransactionsSuccess,
} from "../../../store/action/transaction.action";
// import Table from "./Table";
import ReactPaginate from "react-paginate";
import ErrorApi from "../Common/Error/ErrorApi";
import { deleteAPI } from "../../../store/api-interface";
import TableArtistTrans from "./TableArtistTrans";

class ActiveTransactions extends Component {
  state = {
    transactionsList: 1,
    selectedPage: 1,
    transactionActive: false,
    LoadingIs: false,
    transactionData: [],
    transactionIs: "",
    minBudget: "",
    maxBudget: "",
    deadline: "",
    totalPages: 0,
    closeProjectActive: false,
    deleteActiveIs: false
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const {
          transactionsList,
          transactionIs,
          minBudget,
          maxBudget,
          deadline,
        } = this.state;
        const artistId=this.props.location.pathname.split(':');
        const transactionData = {
          List: transactionsList,
          project: transactionIs,
          min_budget: minBudget,
          max_budget: maxBudget,
          deadline: deadline,
          id:artistId[1]
        };
        this.props.$activeTransactions(transactionData);
      }
    );
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const artistId=this.props.location.pathname.split(':');

    const { transactionIs, minBudget, maxBudget, deadline } = this.state;
    const transactionData = {
      List: selectedData,
      project: transactionIs,
      min_budget: minBudget,
      max_budget: maxBudget,
      deadline: deadline,
      id:artistId[1]
    };
    this.setState({selectedPage: selectedData});
    this.props.$activeTransactions(transactionData);
  };

  handleCloseProject = (id) => {
    this.setState({
      closeProjectActive: true,
    });
    const closeProjectData = { _id: id };
    this.props.$closeProject(closeProjectData);
  };

  handleDeleteProject = (id) => {
    this.setState({deleteActiveIs : true})
    const currentData = { _id : id}
    this.props.$deleteProjectList(currentData)
  }

  handleActiveTransaction = () => {
    const { transactionsList, transactionIs } = this.state;

    this.setState({
      LoadingIs: true,
    });
    const artistId=this.props.location.pathname.split(':');

    const transactionData = {
      List: transactionsList,
      project: transactionIs,
      id:artistId[1]

    };
    
    this.props.$activeTransactions(transactionData);
  };

  componentDidMount() {
    this.handleActiveTransaction();
  }

  handleApiCall = () => {
    this.handleActiveTransaction();
  }

  UpdateNewProposalList = (id,role,proposal_type,indexList) => {
    const OldState = this.props._activeTransactions.activeList
    const activeProjectList = this.props._activeTransactions.activeList.PostProjects
    let data = activeProjectList[indexList]
    console.log(OldState,activeProjectList)
    if(proposal_type=='fixed'){
      for (let x of data.newProposalList){
        for(let y of x.payment_terms){
          if(y._id == id){
            console.log(y)
              if(role==2){
                y.admin_approved = true
              }
              else{
                y.admin_approved_client = true
              }
          }
        }
      }
    }else if(proposal_type=='retainer'){
      for (let x of data.newProposalList){
        for(let y of x.milestoneRetainer){
          if(y._id == id){
            console.log(y)
              if(role==2){
                y.admin_approved = true
              }
              else{
                y.admin_approved_client = true
              }
          }
        }
      }
      }
      else{
        for (let x of data.newProposalList){
          for(let y of x.milestoneActual){
            if(y._id == id){
              console.log(y)
                if(role==2){
                  y.admin_approved = true
                }
                else{
                  y.admin_approved_client = true
                }
            }
          }
        }
      }

      console.log(data.newProposalList)

      OldState.PostProjects[indexList].newProposalList = data.newProposalList

      this.props.$activeTransactionsSuccess(OldState)

  }

  componentWillReceiveProps(nextProps) {

    const activetransaction = nextProps._activeTransactions.activeList;
    const closedProject = nextProps._closeProject.closeProjectIs;
    const deleteProject = nextProps._deleteProjectList.deleteProjectIs;
    const { closeProjectActive, deleteActiveIs } = this.state;
    if (activetransaction) {
      this.setState({
        transactionData: activetransaction.PostProjects,
        LoadingIs: false,
        totalPages: activetransaction.totalPages,
      });
    }
    if (closedProject && closeProjectActive) {
      alert(closedProject.message);
      this.setState({
        closeProjectActive: false,
      });
      this.handleActiveTransaction();
    }
    if(deleteProject && deleteActiveIs){
      this.setState({ deleteActiveIs : false })
      this.handleActiveTransaction();
    }
  }

  onCheckboxClick = (id) => {
    const {headCheckbox, transactionData} = this.state;
    if(id === 'all'){
      const newTransactionList = transactionData.map(tranx => {
        headCheckbox ? tranx.selected = false : tranx.selected = true;
        return tranx
      });
      this.setState({transactionData: newTransactionList, headCheckbox: !this.state.headCheckbox})
    } else {
      const newTransactionList = transactionData.map(tranx => {
        if(tranx._id === id){tranx.selected = !tranx.selected}
        return tranx
      });
      this.setState({transactionData: newTransactionList});
    }
  }

  handleDeleteAll = () => {
    const {transactionData} = this.state;
    this.setState({isDeleting: true});

    const deletedIds = []
    transactionData.forEach(tranx => {
      tranx.selected && deletedIds.push(tranx._id)
    });

    if(window.confirm('Are you sure you want to delete these transactions.')){
      deleteAPI(`/postProject/delete-postproject-multiple`, {_id: deletedIds}).then(res => {
        this.handlePageClick({selected: this.state.selectedPage - 1});
        this.setState({isDeleting: false});
      }).catch(err => {
        console.log(err);
        this.setState({isDeleting: false});
      })
    } else {
      this.setState({isDeleting: false});
    }
  }

  render() {
    const { totalPages, isDeleting } = this.state;
    const transactionFunc = {
      handleOnChange: this.handleOnChange,
      handleCloseProject: this.handleCloseProject,
      handleDeleteProject: this.handleDeleteProject,
      onCheckboxClick: this.onCheckboxClick,
      handleApiCall: this.handleApiCall,
      UpdateNewProposalList:this.UpdateNewProposalList
    };
    const anySelectedTranx = this.state.transactionData.some(val => val.selected);
    return (
      <React.Fragment>
        {this.props._activeTransactions.activeProErrIs ? (
          <ErrorApi {...this.props} />
        ) : (
          <section className="client">
            <div className="container-fluid mx-auto">
              <TableArtistTrans
                {...this.state}
                {...this.props}
                handleTransaction={transactionFunc}
                artistId = {"/transactions/ActiveTransactions"}
              />

              <div className=''>
                {anySelectedTranx && 
                  <button className="btn btn-danger"
                        onClick={this.handleDeleteAll}
                        disabled={isDeleting}
                  >Delete selected</button>}

                {/******** pagination ************* */}

                {totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
const mapState = ({ activeTransactions, closeProject, closedBriefProject, deleteProjectList }) => {
  return {
    _activeTransactions: activeTransactions,
    _closeProject: closeProject,
    _closedBriefProject: closedBriefProject,
    _deleteProjectList: deleteProjectList
  };
};
const mapDispatch = (dispatchEvent) => ({
  $activeTransactions: (values) => dispatchEvent(activeTransactions(values)),
  $closeProject: (values) => dispatchEvent(closeProject(values)),
  $closedBriefProject: (values) => dispatchEvent(closedBriefProject(values)),
  $deleteProjectList: (values) => dispatchEvent(deleteProjectList(values)),
  $activeTransactionsSuccess:(values) => dispatchEvent(activeTransactionsSuccess(values)),
});
export default connect(mapState, mapDispatch)(ActiveTransactions);
