import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { postAPI, postImage } from "../../../../store/api-interface";
import DatePicker from "react-date-picker";
import { useFormik } from "formik";
import * as Yup from 'yup';
import fileSvg from "../../../../Assets/img/svg/file.svg"
import { Link } from "react-router-dom";
import dateSvg from "../../../../Assets/img/svg/date.svg"
import { uploadFileUrl } from "../../../../Utils/BaseUrl";



const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    maxWidth: "1000px",
    width: "100%",
    maxHeight: '800px',
  },
};

const UploadMilestone = ({ showToggleMilestone, handleShowToggleMilestone, updateTransaction, retainerMilestoneEdit, fixedMilestoneEdit, actualMilestoneData, selectEditType,setState }) => {   
 

  const plusHandler = (type) => {
    if (type === "taskList") return uploadFormik.setFieldValue(type, [...uploadFormik.values[type], { name: "" }])
    else uploadFormik.setFieldValue(type, [...uploadFormik.values[type], { type: type, name: "" }])
  }

  const minusHandler = (type, index) => {
    uploadFormik.setFieldValue(type, uploadFormik.values[type]?.filter((ele, i) => { return i !== index }))
  }

  const handleNumbers = (type, feildName, e) => {
    e.preventDefault();
    if (type === "plus") return uploadFormik.setFieldValue(feildName, Number(uploadFormik.values.number_of_hour_worked_on) + 1);
    else return uploadFormik.setFieldValue(feildName, Number(uploadFormik.values.number_of_hour_worked_on) > 0 ? Number(uploadFormik.values.number_of_hour_worked_on) - 1 : 0);
  }

  const handleDate = (e, feildName) => {
    console.log(e, feildName);
    uploadFormik.setFieldValue(feildName, e)
  }

  const hangeFileUpload = async (e, feildName) => {
    try {
      let file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("chatFiles", file);
        const res = await postImage("/backendUser/upload-chat-files", formData);
        if (res.status === 200) {
          console.log(res.data?.chat_files)
          uploadFormik.setFieldValue(feildName, res.data?.chat_files)
          
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadSubmit = async (values) => {
    try {
      console.log(values, "valuesvalues")
      const res = await postAPI("/backendUser/upload-deleverable-files", values)
      if(res){
        alert(res.data.message);
        console.log(res.data)
        handleShowToggleMilestone(false)
        updateTransaction()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const uploadFormik = useFormik({
    initialValues: selectEditType === "fixedMilestoneEdit" ? fixedMilestoneEdit: 
      selectEditType === "retainerMilestoneEdit" ?  retainerMilestoneEdit :
      actualMilestoneData,
    enableReinitialize:true,
    onSubmit: handleUploadSubmit,
    validationSchema: Yup.object().shape({
      file: Yup.array()
        .of(
          Yup.object().shape({            
            name: Yup.string(),
          })
        ),
        
      url: Yup.array()
        .of(
          Yup.object().shape({           
            name: Yup.string()
              .url('Url must be a valid URL.')              
          })
        ),
      description: Yup.string(),
      is_iteration: Yup.boolean(), 
      proposal_type: Yup.string(),
    })
  })

  const dateimg = <img src={dateSvg} />;

  

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={showToggleMilestone}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            type="button"
            className="close media-upload-modal"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => { handleShowToggleMilestone(false) }}
          >
            <span
              aria-hidden="true"
              style={{ color: "black", fontSize: "32px" }}
            >
              &times;
            </span>
          </button>
          <div className="container w-100">    
             
            {Boolean(selectEditType === "retainerMilestoneEdit") && <div className="row row-cols-2 m-0 mb-3 w-100">
              <div className=" mb-2 p-2">
                <label htmlFor="to">From</label>
                <DatePicker
                  value={uploadFormik.values?.start_duration ? new Date(uploadFormik.values?.start_duration) : ''}
                  className="form-control text-center input_md "
                  clearIcon={null}
                  name="date"
                  dayPlaceholder={'From dd'}
                  monthPlaceholder={'mm'}
                  yearPlaceholder={'yyyy'}
                  format="dd/MM/yyyy"
                  calendarIcon={dateimg}
                  calendarClassName="apniClass"
                  showLeadingZeros={true}
                  maxDate={new Date(uploadFormik.values?.end_duration)}
                  // maxDate={uploadFormik?.values?.end_duration ? new Date(uploadFormik.values.end_duration) : new Date() > new Date(props?.mileObj?.end_date) ? new Date(props?.mileObj?.end_date) : new Date()}
                  onChange={(e) => handleDate(e, "start_duration")}
                />
              </div>
              
              <div className=" mb-2 p-2">
                <label htmlFor="to">To</label>
                <DatePicker
                  value={uploadFormik.values?.end_duration ? new Date(uploadFormik.values?.end_duration) : ''}
                  className="form-control text-center input_md "
                  clearIcon={null}
                  name="date"
                  dayPlaceholder={'To dd'}
                  monthPlaceholder={'mm'}
                  yearPlaceholder={'yyyy'}
                  format="dd/MM/yyyy"
                  calendarIcon={dateimg}
                  calendarClassName="apniClass"
                  showLeadingZeros={true}
                  minDate={new Date(uploadFormik.values?.start_duration)}
                  // maxDate={}
                  onChange={(e) => handleDate(e, "end_duration")}
                />
              </div>

            
              <div className="mb-2 p-2">
                <label className="title ">File</label>
                {uploadFormik.values.file?.filter((e) => e.type === "file")?.map((ele, ind) => {
                  return (
                    <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                      <div className="col-10 mb-1 d-flex justify-content-between align-items-center">                        
                        <div className="d-flex w-100 justify-content-between" role="group" aria-label="Basic example">
                          
                          <div className='btn btn_upload bg-primary text-white d-flex align-items-center pt-0 pb-0'  >
                            <label htmlFor={`file[${ind}].name`} style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                              <input type="file" className='btn btn_upload_file d-none ' name={`file[${ind}].name`} id={`file[${ind}].name`} onChange={(e) => { hangeFileUpload(e, `file[${ind}].name`) }} />
                              <span>Upload</span>
                            </label>
                          </div>
                          {ele?.name && <a href={`${uploadFileUrl + ele.name}`} target="__blank" ><img src={fileSvg} alt="" /></a>}
                        </div>
                      </div>

                      <div className=" col-1 d-flex justify-content-around">
                        {uploadFormik.values.file?.length - 1 !== ind ? <div
                          type="button"
                          className=""
                          onClick={(e) => { minusHandler("file", ind) }}
                        >
                          -
                        </div> :
                          <div
                            type="button"
                            // disabled={obj?.restrict ? true : false}
                            className=""
                            onClick={(e) => { console.log("file"); plusHandler("file") }}
                          >
                            +
                          </div>}
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="mb-2 p-2">
                <div className="">
                  <label className="title ">Url</label>
                  {uploadFormik.values?.url?.map?.((ele, ind) => {
                    return (
                      <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                        <div className="col-10 mb-1 d-flex justify-content-between align-items-center">
                          {/* <span className="title ">File</span> */}
                          <div className="" role="group" aria-label="Basic example">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Url"
                              name={`url[${ind}].name`}
                              value={ele?.name}
                              onChange={uploadFormik.handleChange}
                              onBlur={uploadFormik.handleBlur}
                            />
                            
                            {uploadFormik.touched?.url?.[ind]?.name && uploadFormik.errors?.url?.[ind]?.name ? <div className="text-danger"> {uploadFormik.errors?.url?.[ind]?.name} </div> : null}
                          </div>
                        </div>

                        <div className=" col-1 d-flex justify-content-around">
                          {uploadFormik.values.url?.length - 1 !== ind ? <div
                            type="button"
                            className=""
                            onClick={(e) => { minusHandler("url", ind) }}
                          >
                            -
                          </div> :
                            <div
                              type="button"
                              className=""
                              onClick={(e) => { console.log("url"); plusHandler("url") }}
                            >
                              +
                            </div>}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="mb-2 p-2">
                <div className="">
                  <label className="title ">Task list</label>
                  {uploadFormik.values.taskList?.map((ele, ind) => {
                    return (
                      <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                        <div className="col-10 mb-1 d-flex justify-content-between align-items-center">
                          {/* <span className="title ">File</span> */}
                          <div className="btn-group" role="group" aria-label="Basic example">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Task..."
                              name={`taskList[${ind}].name`}
                              value={ele.name}
                              onChange={uploadFormik.handleChange}
                              onBlur={uploadFormik.handleBlur}
                            />
                          </div>
                        </div>

                        <div className=" col-1 d-flex justify-content-around">
                          {uploadFormik.values.taskList?.length - 1 !== ind ? <div
                            type="button"
                            className=""
                            onClick={(e) => { minusHandler("taskList", ind) }}
                          >
                            -
                          </div> :
                            <div
                              type="button"
                              // disabled={obj?.restrict ? true : false}
                              className=""
                              onClick={(e) => { plusHandler("taskList") }}
                            >
                              +
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="mb-2 p-2">
                <div>
                  <label htmlFor="taskList">Work hours</label>
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn"
                      onClick={(e) => handleNumbers("minus", "number_of_hour_worked_on", e)}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      class="form-control text-center input_sm w-25"
                      onKeyDown={(evt) =>
                        (evt.key === "e" || evt.key === "=" || evt.key === "-" || evt.key === "+" || evt.key === "_" || evt.key === ".") &&
                        evt.preventDefault()
                      }
                      name="number_of_hour_worked_on"
                      onChange={uploadFormik.handleChange}
                      value={uploadFormik.values.number_of_hour_worked_on}
                    />
                    <button type="button" class="btn"
                      onClick={(e) => handleNumbers("plus", "number_of_hour_worked_on", e)}
                    >
                      +
                    </button>
                  </div>
                </div>

              </div>
              {/* <div className="mb-2 p-2">
                <label htmlFor="description">Description</label>
                <div className="price_input">
                  <textarea
                    type="text"
                    className={`form-control`}
                    placeholder="Description"
                    name='description'
                    onChange={uploadFormik.handleChange}
                    onBlur={uploadFormik.handleBlur}
                  > </textarea>
                </div>
              </div> */}

             
            </div>}
            {
              Boolean(selectEditType === "fixedMilestoneEdit") && <div className="row row-cols-2 m-0 mb-3 w-100">
                <div className="mb-2 p-2">
                  <label className="title ">File</label>
                  {uploadFormik.values.file?.filter((e) => e.type === "file")?.map((ele, ind) => {
                    return (
                      <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                        <div className="col-10 mb-1 d-flex justify-content-between align-items-center">

                          <div className='btn btn_upload bg-primary text-white d-flex align-items-center pt-0 pb-0'  >
                            <label htmlFor={`file[${ind}].name`} style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                              <input type="file" className='btn btn_upload_file d-none ' name={`file[${ind}].name`} id={`file[${ind}].name`} onChange={(e) => { hangeFileUpload(e, `file[${ind}].name`) }} />
                              <span>Upload</span>
                            </label>
                          </div>
                          {ele?.name && <a href={`${uploadFileUrl + ele.name}`} target="__blank" ><img src={fileSvg} alt="" /></a>}

                        </div>

                        <div className=" col-1 d-flex justify-content-around">
                          {uploadFormik.values.file?.length - 1 !== ind ? <div
                            type="button"
                            className=""
                            onClick={(e) => { minusHandler("file", ind) }}
                          >
                            -
                          </div> :
                            <div
                              type="button"
                              // disabled={obj?.restrict ? true : false}
                              className=""
                              onClick={(e) => { console.log("file"); plusHandler("file") }}
                            >
                              +
                            </div>}
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="mb-2 p-2">
                  <div className="">
                    <label className="title ">Url</label>
                    {uploadFormik.values?.url?.map?.((ele, ind) => {
                      return (
                        <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                          <div className="col-10 mb-1 d-flex justify-content-between align-items-center">
                            {/* <span className="title ">File</span> */}
                            <div role="group" aria-label="Basic example">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Url"
                                name={`url[${ind}].name`}
                                value={ele?.name}
                                onChange={uploadFormik.handleChange}
                                onBlur={uploadFormik.handleBlur}
                              />
                             
                              {uploadFormik.touched?.url?.[ind]?.name && uploadFormik.errors?.url?.[ind]?.name ? <div className="text-danger"> {uploadFormik.errors?.url?.[ind]?.name} </div> : null}
                            </div>
                          </div>

                          <div className=" col-1 d-flex justify-content-around">
                            {uploadFormik.values.url?.length - 1 !== ind ? <div
                              type="button"
                              className=""
                              onClick={(e) => { minusHandler("url", ind) }}
                            >
                              -
                            </div> :
                              <div
                                type="button"
                                className=""
                                onClick={(e) => { console.log("url"); plusHandler("url") }}
                              >
                                +
                              </div>}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="mb-2 p-2">
                  <label htmlFor="description">Description</label>
                  <div className="price_input">
                    <textarea
                      type="text"
                      className={`form-control`}
                      placeholder="Description"
                      name='description'
                      value={uploadFormik.values?.description}
                      onChange={uploadFormik.handleChange}
                      onBlur={uploadFormik.handleBlur}
                    > </textarea>
                  </div>
                </div>
              </div>
            }

            {
              Boolean(selectEditType === "actualMilestoneData") && <div className="row row-cols-2 m-0 mb-3 w-100">
                <div className="mb-2 p-2">
                  <label className="title ">File</label>
                  {uploadFormik.values.file?.filter((e) => e.type === "file")?.map((ele, ind) => {
                    return (
                      <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                        <div className="col-10 mb-1 d-flex justify-content-between align-items-center">

                          <div className='btn btn_upload bg-primary text-white d-flex align-items-center pt-0 pb-0'  >
                            <label htmlFor={`file[${ind}].name`} style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                              <input type="file" className='btn btn_upload_file d-none ' name={`file[${ind}].name`} id={`file[${ind}].name`} onChange={(e) => { hangeFileUpload(e, `file[${ind}].name`) }} />
                              <span>Upload</span>
                            </label>
                          </div>
                          {ele?.name && <a href={`${uploadFileUrl + ele.name}`} target="__blank" ><img src={fileSvg} alt="" /></a>}
                        </div>

                        <div className=" col-1 d-flex justify-content-around">
                          {uploadFormik.values.file?.length - 1 !== ind ? <div
                            type="button"
                            className=""
                            onClick={(e) => { minusHandler("file", ind) }}
                          >
                            -
                          </div> :
                            <div
                              type="button"
                              // disabled={obj?.restrict ? true : false}
                              className=""
                              onClick={(e) => { console.log("file"); plusHandler("file") }}
                            >
                              +
                            </div>}
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="mb-2 p-2">
                  <div className="">
                    <label className="title ">Url</label>
                    {uploadFormik.values?.url?.map?.((ele, ind) => {
                      return (
                        <div className="row p-0 m-0 d-flex justify-content-start align-items-center">
                          <div className="col-10 mb-1 d-flex justify-content-between align-items-center">
                            {/* <span className="title ">File</span> */}
                            <div className="" role="group" aria-label="Basic example">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Url"
                                name={`url[${ind}].name`}
                                value={ele?.name}
                                onChange={uploadFormik.handleChange}
                                onBlur={uploadFormik.handleBlur}
                              />
                              {uploadFormik.touched?.url?.[ind]?.name && uploadFormik.errors?.url?.[ind]?.name ? <div className="text-danger"> {uploadFormik.errors?.url?.[ind]?.name} </div> : null}
                            </div>
                          </div>

                          <div className=" col-1 d-flex justify-content-around">
                            {uploadFormik.values.url?.length - 1 !== ind ? <div
                              type="button"
                              className=""
                              onClick={(e) => { minusHandler("url", ind) }}
                            >
                              -
                            </div> :
                              <div
                                type="button"
                                className=""
                                onClick={(e) => { console.log("url"); plusHandler("url") }}
                              >
                                +
                              </div>}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            }
            <div className="d-flex justify-content-start align-items-end">
              <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); uploadFormik.handleSubmit(); }}>
                Submit
              </button>
            </div>
          </div>
          
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default UploadMilestone;

